import { ApiClient } from "../helpers/api-client";

const apiClient = new ApiClient();

const gorevHareketleri = async (id) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/hareketler/${id}`,
  });
};

const gorevHareketi = async (id) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/hareket/${id}`,
  });
};

const hareketKayit = async (gorev_id, aciklama) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/hareket_kayit`,
    json: {
      gorev_id: gorev_id,
      aciklama: aciklama,
    },
  });
};

const hareketGuncelle = async (gorev_id, aciklama, id) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/hareket_guncelle/${id}`,
    json: {
      gorev_id: gorev_id,
      aciklama: aciklama,
    },
  });
};

const hareketSil = async (id) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/hareket_sil/${id}`,
  });
};

export {
  gorevHareketleri,
  gorevHareketi,
  hareketKayit,
  hareketGuncelle,
  hareketSil,
};
