<template>
  <v-row justify="center">
    <v-dialog
      v-model="modal"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn @click="$emit('hideDialog')" icon dark>
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-switch
              class="mt-4"
              v-model="switch1"
              :label="`${secilen_gorev_durumu.toString()}`"
              color="success"
              hide-details
            ></v-switch>
          </v-toolbar-items>
        </v-toolbar>
        <v-card class="mx-auto">
          <v-card class="mx-auto mt-5" max-width="500">
            <v-card-title class="justify-center">
              <span> {{ gorev_bilgisi.konu }} </span>
            </v-card-title>
            <v-card-text>
              <div>
                <span class="subtitle-1 text--primary">
                  Açıklama : {{ gorev_bilgisi.aciklama }}
                </span>
              </div>
              <div>
                <span class="subtitle-1 text--primary">Durum :</span>
                <span class="subtitle-1 red--text">
                  {{ gorev_bilgisi.aciliyet_durumu }}</span
                >
              </div>
              <div>
                <span class="subtitle-1 text--primary">Oluşturan : </span>
                <span class="subtitle-1 text--primary">
                  {{ gorev_bilgisi.olusturan_kullanici }}</span
                >
              </div>
              <div>
                <span class="subtitle-1 text--primary"
                  >Oluşturma Tarihi :
                </span>
                <span class="subtitle-1 text--primary">{{
                  gorev_bilgisi.olusturma_tarihi | moment("DD-MM-YYYY HH:mm")
                }}</span>
              </div>
            </v-card-text>
            <v-card-action>
              <div></div>
            </v-card-action>
          </v-card>
          <div>
            <v-btn class="mx-5" fab dark color="indigo" @click="tetikle = true">
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </div>
          <v-timeline dense>
            <v-timeline-item
              :key="hareketler.id"
              v-for="hareketler in hareket_bilgisi"
            >
              <v-card class="elevation-2" v-if="hareketler.hareket_aciklamasi">
                <v-card-title class="text-h5">
                  {{ hareketler.kullanici }}</v-card-title
                >
                <v-card-text>
                  {{ hareketler.hareket_aciklamasi }}
                </v-card-text>
                <v-card-actions>
                  <span class="ml-2">{{
                    hareketler.hareket_olusturma_tarihi
                      | moment("DD-MM-YYYY HH:mm")
                  }}</span>
                  <v-spacer></v-spacer>
                  <div>
                    <v-btn
                      class="mr-2"
                      color="primary"
                      @click="duzenle(hareketler.id)"
                    >
                      Düzenle
                    </v-btn>
                  </div>
                  <v-btn color="error" @click="sil(hareketler.id)"> Sil </v-btn>
                </v-card-actions>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card>
        <Crud
          :modal="tetikle"
          :gelen_id="gelen_id"
          :gorev_id="gorev_id"
          @hideDialog="showClose()"
        ></Crud>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { gorevHareketleri, hareketSil } from "../../../query/gorev_hareketleri";
import { gorevBilgileri, gorevDurumDegistir } from "../../../query/gorevler";
import Crud from "./Crud.vue";
export default {
  // watch: {
  //   async modal() {
  //     this.hareket_bilgisi = await gorevHareketleri(this.gorev_id);
  //     this.gorev_bilgisi = await gorevBilgileri(this.gorev_id);
  //     // this.gorev_id = this.$route.params.id;
  //     console.log("gorev_id", this.gorev_id);
  //     if (this.gorev_bilgisi.gorev_durumu === true) {
  //       this.switch1 = true;
  //       this.secilen_gorev_durumu = "Açık";
  //     } else {
  //       this.switch1 = false;
  //       this.secilen_gorev_durumu = "Kapalı";
  //     }
  //   },
  // },
  // async mounted() {
  //   this.hareket_bilgisi = await gorevHareketleri(this.gorev_id);
  //   this.gorev_bilgisi = await gorevBilgileri(this.gorev_id);
  //   // this.gorev_id = this.$route.params.id;
  //   console.log("gorev_id", this.gorev_id);
  //   if (this.gorev_bilgisi.gorev_durumu === true) {
  //     this.switch1 = true;
  //     this.secilen_gorev_durumu = "Açık";
  //   } else {
  //     this.switch1 = false;
  //     this.secilen_gorev_durumu = "Kapalı";
  //   }
  // },
  methods: {
    async sil(id) {
      const result = await this.$dialog.error({
        text: "Kayıt silinsin mi?",
        title: "Dikkat",
        actions: {
          true: {
            color: "error",
            text: "Evet",
          },
          false: "Hayır",
        },
      });
      if (result) {
        const sonuc = await hareketSil(id);
        if (sonuc !== undefined) {
          this.hareket_bilgisi = await gorevHareketleri(this.gorev_id);
        }
      }
    },
    duzenle(id) {
      this.gelen_id = id;
      this.tetikle = true;
    },
    async showClose() {
      this.tetikle = false;
      this.hareket_bilgisi = await gorevHareketleri(this.gorev_id);
      this.gelen_id = null;
    },
    async showCloseDialog() {
      this.modal = false;
    },
  },
  props: { modal: null, gorev_id: null },
  data() {
    return {
      hareket_bilgisi: [],
      gorev_bilgisi: [],
      tetikle: false,
      gelen_id: null,
      // gorev_id: null,
      switch1: false,
      secilen_gorev_durumu: "Görev Durumu Kapalı",
    };
  },

  watch: {
    switch1(val) {
      if (val === false) {
        this.secilen_gorev_durumu = "Görev Durumu Kapalı";
      } else {
        this.secilen_gorev_durumu = "Görev Durumu Açık";
      }
    },
    async secilen_gorev_durumu(val) {
      if (val === "Görev Durumu Kapalı") {
        this.tetikle = true;
        await gorevDurumDegistir(this.gorev_id, false);
      } else {
        await gorevDurumDegistir(this.gorev_id, true);
      }
    },
    async modal() {
      this.hareket_bilgisi = await gorevHareketleri(this.gorev_id);
      this.gorev_bilgisi = await gorevBilgileri(this.gorev_id);
      // this.gorev_id = this.$route.params.id;
      console.log("gorev_id", this.gorev_id);
      if (this.gorev_bilgisi.gorev_durumu === true) {
        this.switch1 = true;
        this.secilen_gorev_durumu = "Görev Durumu Açık";
      } else {
        this.switch1 = false;
        this.secilen_gorev_durumu = "Görev Durumu Kapalı";
      }
    },
  },
  components: { Crud },
};
</script>

<style></style>
