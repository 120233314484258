import { ApiClient } from "../helpers/api-client";

const apiClient = new ApiClient();

const gorevlerListesi = async (tumGorevler) => {
  if (tumGorevler) {
    return await apiClient.makeRequest({
      url: `${process.env.VUE_APP_API_URL}/gorevler`,
    });
  } else {
    return await apiClient.makeRequest({
      url: `${process.env.VUE_APP_API_URL}/gorevlerim`,
    });
  }
};

const bitenGorevListesi = async () => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/biten_gorevler`,
  });
};

const gorevBilgileri = async (id) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/gorev/${id}`,
  });
};

const gorevKayit = async (
  firma_id,
  konu,
  aciklama,
  aciliyet_durumu,
  atanan_kullanici
) => {
  console.log("geldiiiii", atanan_kullanici);
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/gorev_kayit`,
    json: {
      firma_id: firma_id,
      konu: konu,
      aciklama: aciklama,
      aciliyet_durumu: aciliyet_durumu,
      atanan_kullanici: atanan_kullanici,
    },
  });
};

const gorevGuncelle = async (
  firma_id,
  konu,
  aciklama,
  aciliyet_durumu,
  atanan_kullanici,
  id
) => {
  console.log("zibaaaaaaaa", atanan_kullanici);
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/gorev_guncelle/${id}`,
    json: {
      firma_id: firma_id,
      konu: konu,
      aciklama: aciklama,
      aciliyet_durumu: aciliyet_durumu,
      atanan_kullanici: atanan_kullanici,
    },
  });
};

const gorevDurumDegistir = async (id, gorev_durumu) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/gorev_durumu/${id}`,
    json: {
      gorev_durumu: gorev_durumu,
    },
  });
};

const gorevSil = async (id) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/gorev_sil/${id}`,
  });
};

export {
  gorevlerListesi,
  bitenGorevListesi,
  gorevBilgileri,
  gorevKayit,
  gorevGuncelle,
  gorevSil,
  gorevDurumDegistir,
};
