<template>
  <div class="text-center">
    <v-dialog persistent v-model="modal" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ cardText }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="gorev_bilgisi.aciklama"
            name="aciklama"
            id="aciklama"
            label="Açıklama"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" dark @click="$emit('hideDialog')">Vazgeç</v-btn>
          <v-btn color="primary" @click="crud"> {{ buttonText }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  gorevHareketi,
  hareketKayit,
  hareketGuncelle,
} from "../../../query/gorev_hareketleri";

export default {
  watch: {
    async modal() {
      console.log("girdim tatlım", this.gorev_id);
      this.crudFunction(this.gelen_id);
    },
  },

  props: { modal: null, gelen_id: null, gorev_id: null },
  data() {
    return {
      gorev_bilgisi: [],
      firmalar: [],
      ilceler: [],
      buttonText: "Kaydet",
      cardText: "Yeni Hareket",
      crud: this.kaydet,
      secilen_gorev_durumu: true,
    };
  },

  methods: {
    async crudFunction(gelen_id) {
      console.log("bakayim", this.gorev_id);
      if (this.gelen_id != null) {
        console.log("zz ", gelen_id);
        this.buttonText = "Güncelle";
        this.cardText = "Bilgileri Güncelle";
        this.crud = this.guncelle;
        this.gorev_bilgisi = await gorevHareketi(this.gelen_id);
      } else {
        this.gorev_bilgisi = [];
        this.buttonText = "Kaydet";
        this.cardText = "Yeni Hareket";
        this.crud = this.kaydet;
      }
    },
    async kaydet() {
      const sonuc = await hareketKayit(
        (this.gorev_bilgisi.gorev_id = this.gorev_id),
        this.gorev_bilgisi.aciklama
      );
      if (this.secilen_gorev_durumu)
        if (sonuc !== undefined) {
          this.$emit("hideDialog");
        }
    },
    async guncelle() {
      const sonuc = await hareketGuncelle(
        this.gorev_bilgisi.gorev_id,
        this.gorev_bilgisi.aciklama,
        this.gelen_id
      );
      if (sonuc !== undefined) {
        this.$emit("hideDialog");
      }
    },
  },
};
</script>
